var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-histogram',{attrs:{"data":_vm.item,"grid":{left:'5%',right: '5%'},"legend":{bottom:0},"judge-width":"","settings":{
                label: { show: false, position: 'top'},
                yAxisType: ['KMB', 'KMB'],
                axisSite: { right: ['公海客户领取数'] }, 
                areaStyle:{
                    area: true,
                    normal:{
                        color:{
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 1, color: '#effffb' // 0% 处的颜色
                            }, {
                                offset: 0, color: '#23d9ae' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        shadowColor: 'rgba(0, 0, 0, 0.1)',
                        shadowBlur: 10
                    }
                },
            },"extend":_vm.histogramChartExtend}}),(_vm.first>0 && _vm.item.rows.length == 0)?_c('LEmpty'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }