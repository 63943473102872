<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生客户分析</a-breadcrumb-item>
            <a-breadcrumb-item>客户公海分析</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                        日
                        </a-radio-button> 
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                    <!-- <a-form-item>
                        <a-select style="width: 160px" placeholder="选择员工" showSearch :filterOption="filterOption" @search="handleSearch" allowClear @change="handleEmployee">
                          <a-select-option v-for="(item,index) in staffList" :key="index"  :value="item.employee_id">{{item.filter_name}}</a-select-option>
                        </a-select>
                    </a-form-item> -->

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>

                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <pool :loading='loading'  :item ='leads_data'/>
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges";
import moment from 'moment'
import pool from './pool'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'sea',
  components:{
    pool,
    statistics
  },
  data() {
    return {
      loading:false,
      leads_data:{},
      counts:[],
      studios:[],
      staffList:[],
      paramsData:{
        obj_id:'',
        studio_id:'',
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        filter_type:'day'
      },
    }
  },
  mixins: [ranges],
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisCrmCustomerPoolAction', obj)
      this.leads_data = res.data.cycle
      this.loading = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getStaff(val){
      let obj = {q:val};
      let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:obj})
      this.staffList = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisCrmCustomerCountAction', obj)
      this.counts = res.data
    },
    handleSearch(val){
      this.getStaff(val)
    },
    handleEmployee(value){
        this.paramsData.obj_id = value
    },
    handleChange(value){
      this.paramsData.studio_id = value
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>

