<template>

    <a-card title="">
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <ve-histogram 
                :data="item" 
                :grid="{left:'5%',right: '5%'}"
                :legend="{bottom:0}"
                judge-width
                :settings="{
                    label: { show: false, position: 'top'},
                    yAxisType: ['KMB', 'KMB'],
                    axisSite: { right: ['公海客户领取数'] }, 
                    areaStyle:{
                        area: true,
                        normal:{
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 1, color: '#effffb' // 0% 处的颜色
                                }, {
                                    offset: 0, color: '#23d9ae' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                            shadowColor: 'rgba(0, 0, 0, 0.1)',
                            shadowBlur: 10
                        }
                    },
                }" 
                :extend="histogramChartExtend"
                ></ve-histogram>
            <LEmpty v-if="first>0 && item.rows.length == 0"/>
        </div>
    </a-card>

</template>

<script>
    export default {
        name:"pool",
        data () {
            this.markLine = {
                symbol: ['none', 'none'], //去掉箭头
                data: [
                    {
                    name: '平均线',
                    type: 'average'
                    }
                ]
            }
            return{
                first:0,
                histogramChartExtend:{

                        series:{
                            type:'bar',
                            barMaxWidth:10,
                            margin:80,
                            itemStyle:{
                                normal:{
                                    barBorderRadius:[30,30,0,0]
                                }
                            },
                        },
                        xAxis:{
                            axisLine:{
                                show:true
                            }
                        },
                        yAxis(item){
                            item[0].axisLine = Object.assign({}, {
                                show:true
                            })
                            item[1].axisLine = Object.assign({}, {
                                show:true
                            })
                            item[0].splitLine = Object.assign({}, {
                                show:false,
                                lineStyle: {
                                    type: 'solid'
                                }
                            })
                            item[1].splitLine = Object.assign({}, {
                                show:false,
                                lineStyle: {
                                    type: 'dotted'
                                }
                            })
                            item[0].axisLabel = Object.assign({}, {
                                    formatter:function(value,index){
                                        return `${value}个`
                                    }
                            })
                            item[1].axisLabel = Object.assign({}, {
                                    formatter:function(value,index){
                                        return `${value}次`
                                    }
                            })
                            
                            return item
                        },




                }
            }
        },
        props:['item', 'loading'],
        watch: {
            loading(newValue, oldValue) {
                this.first++;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>